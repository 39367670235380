import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Container, Row, Col, Button } from 'react-bootstrap'

import { Steps, Progress } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import '../../ScoutOnboarding/ScoutOnboarding.scss'

import LocationPage from '../../LocationPage/LocationPage'
import SectorPage from '../../SectorPage/SectorPage'
import StartupInfoPage from './StartupInfoPage'
import StartupCapitalPage from '../StartupCapitalPage/StartupCapitalPage'
import MilestonesPage from '../MilestonesPage/MilestonesPage'
import TeamInfoPage from '../TeamInfoPage/TeamInfoPage'
import IdentifyAsAnyOne from '../../IdentifyAsAnyOne/IdentifyAsAnyOne'
import SlideDeckPage from '../SlideDeckPage/SlideDeckPage'
import InviteCode from '../SignUpStartup/InviteCode/InviteCode'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { SaveAndExitClicked } from '../../../redux/startupFlowControl/startupFlowControlActions'
import { fetchStartupUserInfoUpdate } from '../../../redux/auth/authActions'
import HeaderOnboarding from '../../../components/Header/HeaderOnboarding/HeaderOnboarding'
import StartupStepFinishIcon from '../../../assets/images/Icons/StartupStepFinishIcon'
import StartupStepDoneIcon from '../../../assets/images/Icons/StartupStepDoneIcon'
import './StartupInfoPage.scss'
import '../../../pages/InvestorOnboarding/Step10AccreditedInvestorOrNot/Step10AccreditedInvestorOrNot.scss'
import '../../../pages/InvestorOnboarding/InvestorOnboarding.scss'
import Loader from '../../../components/Loader/Loader'
const StartupInformation = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { Step } = Steps
  const [count, setCount] = React.useState(0)
  const [countinueButtonEnabled, setCountibueButtonEnabled] = React.useState(false)
  const [startInfoFlag, setStartInfoFlag] = React.useState(
    history.location.state?.gif === 'false' ? false : true,
  )
  const [current, setCurrent] = React.useState(0)
  const [inviteCode, setInviteCode] = React.useState('true')
  const { loading, startupUser } = useSelector(state => state.auth)
  const [roundinfoState, setRoundinfoState] = useState({
    round_size: '',
    raised: '',
    prior_rounds_total_raised: '',
  })

  /**
   * Sets the invite code in the component's state.
   * @param {string} code - The invite code to be set.
   */
  const verifyInviteCode = code => {
    setInviteCode(code)
  }

  // Handles the click event when continuing to the next step.
  const onCountinueClick = () => {
    next()
    setCount(count + 10)
  }

  // Dispatches an action to toggle a startup button and fetches startup user information.
  useEffect(() => {
    dispatch({
      type: 'TOGGLE_STARTUP_BUTTON',
    })
    dispatch(fetchStartupUserInfoUpdate({}))
  }, [])

  /**
   * Effect to run when the `current` value changes.
   * Scrolls the body to the top and updates the URL based on the current step.
   * @param {number} current - The current step value.
   */
  useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
    window.history.pushState({}, '', `/startup/onboarding/step-${current + 1}`)
  }, [current])

  /**
   * Effect to run when the status of the startup user changes.
   * Redirects the user based on their status.
   * @param {string} props.startupUser?.status?.status - The status of the startup user.
   */
  useEffect(() => {
    if (props.startupUser?.status?.status == 'approved') {
      if (props.startupUser?.thankyou_screen_viewed == true) {
        props.history.push('/startup/approve/dashboard/status')
      } else {
        props.history.push('/startup/thankyou')
      }
    } else if (props.startupUser?.status?.status == 'tracking') {
      if (props.startupUser?.thankyou_screen_viewed == true) {
        props.history.push('/tracking/monthly-update')
      } else {
        props.history.push('/startup/thankyou')
      }
    } else if (
      props.startupUser?.status?.status == 'rejected' ||
      props.startupUser?.status?.status == 'pending'
    ) {
      props.history.push('/startup/thankyou')
    }
  }, [props.startupUser?.status?.status])

  /**
   * Array of steps representing different sections of the onboarding process.
   * @description - Each step contains a title, icon, and content component.
   */
  const steps = [
    {
      title: 'Step - 1',
      icon: <span className="StepIcon">1</span>,
      content: (
        <StartupInfoPage
          setCountibueButtonEnabled={setCountibueButtonEnabled}
          onCountinueClick={onCountinueClick}
          startInfoFlag={startInfoFlag}
          setStartInfoFlag={setStartInfoFlag}
        />
      ),
    },
    {
      title: 'Step - 2',
      icon: <span className="StepIcon">2</span>,
      content: <LocationPage onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Step - 3',
      icon: <span className="StepIcon">3</span>,
      content: <SectorPage onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Step - 4',
      icon: <span className="StepIcon">4</span>,
      content: (
        <StartupCapitalPage
          onCountinueClick={onCountinueClick}
          setRoundinfoState={setRoundinfoState}
          roundinfoState={roundinfoState}
        />
      ),
    },
    {
      title: 'Step - 5',
      icon: <span className="StepIcon">5</span>,
      content: <MilestonesPage onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Step - 6',
      icon: <span className="StepIcon">6</span>,
      content: <TeamInfoPage onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Step - 7',
      icon: <span className="StepIcon">7</span>,
      content: <IdentifyAsAnyOne onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Step - 8',
      icon: <span className="StepIcon">8</span>,
      content: <SlideDeckPage onCountinueClick={onCountinueClick} />,
    },
    {
      title: 'Step - 9',
      icon: <span className="StepIcon">9</span>,
      content: <InviteCode verifyInviteCode={verifyInviteCode} />,
    },
    {
      title: 'Done',
      icon: <StartupStepDoneIcon />,
      content: <div></div>,
    },
  ]

  //Function to move to the next step in the onboarding process.
  const next = () => {
    setCurrent(current + 1)
  }

  /**
   * Function to move to the previous step in the onboarding process.
   * Also updates the count and dispatches a fetch action for startup user information.
   */
  const prev = () => {
    setCount(count - 10)
    setCurrent(current - 1)
    dispatch(fetchStartupUserInfoUpdate({ onboarding_step: current - 1 }))
  }

  /**
   * Effect to run when the component mounts or when the onboarding step changes.
   * Handles redirection based on the status and onboarding step of the startup user.
   */
  useEffect(() => {
    if (props.startupUser?.status?.status == 'incomplete') {
      if (props.startupUser.onboarding_step) {
        if (props.startupUser.onboarding_step !== 0) {
          if (props.startupUser.onboarding_step === 9) {
            history.push('/startup/thankyou')
          } else {
            if (props.startupUser.onboarding_step !== 0) {
              setStartInfoFlag(false)
            }
            setCurrent(props.startupUser.onboarding_step)
            if (count) {
              setCount(count)
            } else {
              setCount(count + props.startupUser.onboarding_step * 10)
            }
          }
        }
      } else {
        setCurrent(0)
        setCount(count)
      }
    }
  }, [props.startupUser.onboarding_step])
  return (
    <>
      <HeaderOnboarding
        onSaveAndLogout={() => dispatch(SaveAndExitClicked())}
        showSaveAndLogout={!startInfoFlag}
      />
      {/* Container for the step indicators */}
      {loading ? (
        <Loader />
      ) : (
        <>
          {startInfoFlag ? (
            <span></span>
          ) : (
            <span>
              <Container className="mb-3 mt-5">
                <Row>
                  <Col lg={5} md={7} xs={current == 0 ? 8 : 12} className="mx-auto">
                    <Steps className="startup-steps" current={current >= 1 ? 1 : 0}>
                      {steps.map(
                        (item, i) =>
                          i < current + 2 &&
                          i > current - 2 && (
                            <Step
                              className={i === 0 ? 'firstStep' : i === 9 ? 'doneStep' : ''}
                              icon={i < current ? <StartupStepFinishIcon /> : item.icon}
                              key={i}
                              title={item.title}
                            />
                          ),
                      )}
                    </Steps>
                  </Col>
                </Row>
              </Container>
              <Container className="mb-3">
                <Row>
                  <Col md={8} className="mx-auto mb-3">
                    <Progress percent={Math.round(8.333333333 + count)} />
                  </Col>
                </Row>
              </Container>
            </span>
          )}

          {/*** Progress Bar is here ***/}
          {steps[current].content}

          {/* New Back & Continue buttons */}
          <section className="bottomBtns">
            <Container className="">
              <Row>
                <Col md={4} xs={12} className="text-center mx-auto">
                  {current > 0 && (
                    <Button
                      className="backBtnOnboard btn-block mt-xs-0 mb-xs-0 mt-3 mb-5"
                      onClick={() => {
                        prev()
                      }}
                    >
                      <ArrowLeftOutlined /> Back
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}
    </>
  )
}
const mapStateToProps = state => ({
  stepStartup: state.startupflowControl.stepStartup,
  investorUser: state.auth.user,
  startupUser: state.auth.startupUser,
})

const mapDispatchToProps = dispatch => ({
  saveAndExitClicked: () => dispatch(SaveAndExitClicked()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StartupInformation))
