import React from 'react'

import TRENDING_SECTOR_DATA from './TrendingCardData'
import { SECTOR_DATA, SECTOR_DATA_IMAGES } from '../../pages/SectorPage/SectorData'
import { connect, useDispatch, useSelector } from 'react-redux'

import './TrendingCardList.scss'
import { Col, Container, Row } from 'react-bootstrap'

import {
  prevStepInvestor,
  nextStepInvestor,
} from '../../redux/investorFlowControl/investorFlowControlActions'

import { prevStepStartup, nextStepStartup } from '../../redux/startupFlowControl/startupFlowControlActions'

import {
  updateLocalInvestorUser,
  updateLocalScoutUser,
  updateLocalStartupUser,
} from '../../redux/auth/authActions'
import { message } from 'antd'

// Displays a list of trending sectors with checkboxes for user selection.
const TrendingCardList = ({
  investor,
  investorUser,
  startupUser,
  updateLocalStartupUser,
  updateLocalInvestorUser,
  startup,
}) => {
  const { scout, scoutUser } = useSelector(state => state.auth)
  const sectors = investor
    ? investorUser?.expertise_type === 'Generalist'
      ? investorUser?.sectors?.length
        ? SECTOR_DATA?.filter(item => !investorUser?.sectors?.includes(item))
        : []
      : investorUser.sectors || []
    : scout
    ? scoutUser?.sector_interests || []
    : startupUser.sectors
  const dispatch = useDispatch()

  const maxSectorsSelected = sectors?.length === 8

  const sectorsCopy = sectors || []

  /**
   * Handles the change event when a checkbox is clicked.
   *
   * @param {Object} e - The event object.
   * @param {Array} sectors - The array of selected sectors.
   */
  const handleCheckBoxChange = (e, sectors) => {
    if (e.target.checked) {
      if (!sectors?.includes(e.target.name)) {
        sectorsCopy.push(e.target.name)
      }
    } else {
      if (sectors?.includes(e.target.name)) {
        const index = sectorsCopy?.findIndex(element => element === e.target.name)
        sectorsCopy.splice(index, 1)
      }
    }
    if (investor) {
      let newSectors = sectorsCopy
      if (investorUser?.expertise_type == 'Generalist') {
        newSectors = SECTOR_DATA.filter(item => !sectorsCopy?.includes(item))
      }
      updateLocalInvestorUser({ sectors: newSectors })
    } else if (scout) {
      dispatch(updateLocalScoutUser({ sector_interests: sectorsCopy }))
    } else {
      updateLocalStartupUser({ sectors: sectorsCopy })
    }
  }

  /**
   * Generates the JSX elements for displaying sectors with checkboxes.
   *
   * @type {Array} JSX.Element
   */
  const sectorsComponent = SECTOR_DATA?.filter(item => item !== 'Other')
    .sort((a, b) => a.localeCompare(b))
    ?.concat(SECTOR_DATA?.find(item => item === 'Other'))
    .map((sector, i) => {
      const SectorImage = SECTOR_DATA_IMAGES[sector]
      return (
        <div
          className={
            sectors?.includes?.(sector) ? 'selected3 card-component-sectors2' : 'card-component-sectors2'
          }
          key={i}
        >
          <div>
            <input
              checked={sectors?.includes(sector)}
              onChange={event => {
                handleCheckBoxChange(event, sectors)
              }}
              id={sector}
              name={sector}
              style={{ opacity: '0', visibility: 'hidden', width: '-20px' }}
              type="checkbox"
              disabled={maxSectorsSelected && !sectors?.includes(sector) && startup}
            />
            <label name={sector} htmlFor={sector}>
              <span
                className={
                  sectors?.includes(sector)
                    ? 'sector-text-color border-sector d-flex align-items-center  justify-content-center border-sector-selected'
                    : 'd-flex align-items-center  justify-content-center border-sector'
                }
                style={
                  maxSectorsSelected && !sectors?.includes(sector) && startup ? { color: '#B5B5B5' } : null
                }
              >
                {SECTOR_DATA_IMAGES[sector] && <SectorImage />}
                {sector}
              </span>
            </label>
          </div>
        </div>
      )
    })

  // const otherComponent = () => {

  //   return (
  //     <div
  //       className={sectors.includes('Other') ? 'selected3 card-component-sectors2' : 'card-component-sectors2'}
  //     >
  //       <div className="grow ma1">
  //         <input
  //           checked={sectors.includes('Other')}
  //           onChange={event => {
  //             if (maxSectorsSelected && !sectors.includes('Other')) {
  //               message.error('You can not select more than 8')
  //             } else {
  //               handleCheckBoxChange(event, sectors)
  //             }
  //           }}
  //           id={'Other'}
  //           name={'Other'}
  //           style={{ opacity: '0', visibility: 'hidden', width: '-20px' }}
  //           type="checkbox"
  //           disabled={maxSectorsSelected && !sectors.includes('Other') && startup}
  //         />
  //         <label name={'Other'} htmlFor={'Other'}>
  //           <span
  //             className={
  //               sectors.includes('Other') ? 'sector-text-color border-sector-selected' : 'border-sector'
  //             }
  //             style={maxSectorsSelected && !sectors.includes('Other') && startup ? { color: '#B5B5B5' } : null}
  //           >
  //             {SECTOR_DATA_IMAGES['Other'] && (
  //               <img src={SECTOR_DATA_IMAGES['Other']} alt="" className="sectorIcon" />
  //             )}
  //             {'Other'}
  //           </span>
  //         </label>
  //       </div>
  //     </div>
  //   )
  // }
  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={12} className="mx-auto Trending-column" style={{ marginTop: 30 }}>
            <p style={{ textAlign: 'center' }}>
              <span className="trendingHeading font-weight-bold text-uppercase">Trending</span>
            </p>
          </Col>

          <Col md={12} className="mx-auto mb-3" style={{ textAlign: 'center' }}>
            <div className="input-sector-container">{sectorsComponent}</div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  updateUserInfoFailed: state.investorflowControl.updateUserInfoFailed,
  investor: state.auth.investor,
  company: state.auth.user.company,
  investorUser: state.auth.user,
  startupUser: state.auth.startupUser,
  updateStartupUserInfoFailed: state.startupflowControl.updateUserInfoFailed,
})

const mapDispatchToProps = dispatch => ({
  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  nextStepInvestor: () => dispatch(nextStepInvestor()),
  prevStepInvestor: () => dispatch(prevStepInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  prevStepStartup: () => dispatch(prevStepStartup()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TrendingCardList)
