import React, { Component } from 'react'
import './App.css'
import Spinner from 'react-bootstrap/Spinner'
import ReactGA from 'react-ga'
import jwt_decode from 'jwt-decode'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { setJwtToken } from '../redux/auth/authActions'
import { publicLinkActivation } from '../redux/startupApprovedFlowControl/StratupApprovedFlowControlActions'
import { getTokenInLocaleStorage, setTokenInLocalStorage } from '../utils/general'
import { emitPageEvent } from '../metrics/metricsEmitter'

import AngelOnboardingFormsitePage from '../pages/AngelCommunityPage/AngelOnboardingFormsitePage'
import AngelOnboardingTypeformPage from '../pages/AngelCommunityPage/AngelOnboardingTypeformPage'
import SectorProfilePage from '../pages/SectorProfilePage/SectorPage'
import SignUpStartup from '../pages/Startup/SignUpStartup/SignUpStartup'
import TrackingMonthlyUpdatesForm from '../pages/TrackingPortal/TrackingMonthlyUpdatesForm/TrackingMonthlyUpdatesForm'
import LoginStartup from '../components/LoginForm/LoginStartup'

import InvestorAlreadyDeletePage from '../pages/Investor/InvestorDeletePage/InvestorAlreadyDeletePage'
import InvestorFirstInvitePage from '../pages/Investor/InvestorWelcomeBackPage/InvestorFirstInvitePage'
import InvestorSecondInvitePage from '../pages/Investor/InvestorWelcomeBackPage/InvestorSecondInvitePage'
import InvestorAfterInvitePage from '../pages/Investor/InvestorWelcomeBackPage/InvestorAfterInvitePage'

import CoFounderPage from '../pages/CoFounderPage/CoFounderPage'
import StartupDashboard from '../pages/Startup/StartupDashboard/StartupDashboard'
import StartupFeedbackPage from '../pages/Startup/StartupFeedbackPage/StartupFeedbackPage'
import StartupInvestorCard from '../pages/Startup/StartupInvestorCard/StartupInvestorCard'
import StartupProfilePage from '../pages/Startup/StartupProfilePage/StartupProfilePage'
import StartupPreviewPage from '../pages/Startup/StartupPreviewPage/StartupPreviewPage'
import StartupProfileEditPage from '../pages/Startup/StartupProfilePage/StartupProfileEditPage'
import StartupSettingsPage from '../pages/Startup/StartupSettingsPage/StartupSettingsPage'
import StartupReferPage from '../pages/Startup/StartupReferPage/StartupReferPage'
import StartupSeeMorePage from '../pages/Startup/StartupSeeMorePage/StartupSeeMorePage'
import StartupChangePWPage from '../pages/Startup/StartupSettingsPage/StartupChangePWPage'
import StartupLogOutPage from '../pages/Startup/StartupLogOutPage/StartupLogOutPage'
import StartupNotificationsPage from '../pages/Startup/StartupNotificationsPage/StartupNotificationsPage'
import StartupPrivacyPage from '../pages/Startup/StartupPrivacyPage/StartupPrivacyPage'
import StartupAccountPage from '../pages/Startup/StartupAccountPage/StartupAccountPage'
import StartupDeletePage from '../pages/Startup/StartupDeletePage/StartupDeletePage'
import StartupAlreadyDeletePage from '../pages/Startup/StartupDeletePage/StartupAlreadyDeletePage'
import StartupDeleteConfirmPage from '../pages/Startup/StartupDeletePage/StartupDeleteConfirmPage'
import StartupMobilePWPage from '../pages/Startup/StartupSettingsPage/StartupMobilePWPage'
import LoginForm1 from '../components/LoginForm/LoginForm1'
import Suspend from './Suspend/suspend'
import ThankYouPage from '../pages/Startup/SignUpStartup/ThankYouPage/ThankYouPage'
import FooterInvestorMobile from '../components/Footer/FooterInvestorMobile/FooterInvestorMobile'

//education get accredited routes
import { getInvestorProfile } from '../redux/investorFlowControl/investorFlowControlActions'
import {
  ScoutSignup,
  LoginScouts,
  ScoutProfile,
  ScoutSettings,
  ScoutInvite,
  ScoutReferrals,
  ScoutContracts,
  ScoutNotifications,
  ScoutOnboarding,
  Step06Congratulations,
  ScoutEmailConfirmPage,
  ScoutInviteHistory,
  ScoutDeals,
  ScoutContractsAddEntity,
  ScoutSettingsAddRecipient,
} from '../containers/ScoutLazyLoading'
import {
  Faqs,
  NewHomePage,
  BlogListPage,
  PodcastList,
  PodcastViewDetails,
  BlogViewDetails,
  TermsPage,
  PolicyPage,
  // ThankYouPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  Portfolio,
} from './OtherLazyLoading'

import {
  StartupApprovedProfileMain,
  MonthlyUpdateAnalytic,
  MonthlyUpdateHistory,
  StatusMain,
  QAFormMain,
  InvestorHistoryMain,
  InvestorFeedbackMain,
  MonthlyUpdateform,
  PreviewInvestorView,
  StartupNotification,
  StartupApprovedSetting,
  EditMonthlyUpdateform,
  InvestorDetail,
  OtpInput,
  StartupDetail,
  StartupMonthlyUpdates,
  Fundraise,
  PostDetails,
  HistoryViewPost,
  StartupDetailByLinkType,
  PreviewInvestorViewNew,
} from './ApprovedStartupLazyLoading'

import {
  InvestorPassedHistory,
  InvestorEmailNotification,
  InvestorChangePassword,
  SignUpInvestor,
  InvestorProfile,
  InvestorDealDetail,
  MonthlyUpdates,
  PassDeal,
  PublicQAForum,
  ReviewMemo,
  InvestorInvestingSteps,
  InvestorHistory,
  InvestorSetting,
  InvestorTour,
  InvestorTourHistory,
  InvestorTourKarma,
  InvestorTourProfile,
  InvestorNotifications,
  InvestorDeals,
  InvestorStepPage,
  IncreaseInvestment,
  Step12AccreditionStatus,
  LoginInvestor,
  InvestorSettingTaxes,
  InvestorKyc,
  CompleteKyc,
  EnterEmail,
  BankingDocVerification,
  BankingProfileInfo,
  InvestorDealDwolla,
  InvestorAnotherInvestment,
  MonthlyUpdatesAnalytics,
  InvestorSettings,
} from '../containers/InvestorLazyLoading'

import {
  Hiring,
  LoginHiring,
  RegisterHiring,
  HiringStepPage,
  Congratulations,
  AboutYourStartup,
} from '../containers/RecruitLazyLoading'

import StartupInformation from '../pages/Startup/StartupInfoPage/StartupInformation'

import {
  LearnInvestProtectedRoute,
  Series65ProtectedRoute,
  StartupProtectedRoute,
  InvestorProtectedRoute,
  ScoutProtectedRoute,
} from './RouteLazyLoading'

import {
  ProfileMain,
  ProfileSettingMain,
  TrackingPreviewInvestorView,
  TrackingMonthlyUpdates,
  EditTrackingMonthlyUpdates,
  TrackingHistoryUpdates,
  TrackingNotification,
} from '../containers/TrackingLazyLoading'

import {
  GetAccreditedSignup,
  GetAccreditedLogin,
  GetAccreditedSyllabus,
  EducationEmailConfirmPage,
  GetAccreditedSyllabusSelect,
  GetAccreditedReadingMaterialDetails,
  GetAccreditedVideoLectureDetails,
  GetAccreditedQuizzes,
  GetAccreditedQuizDetalis,
  GetAccreditedQuizResult,
  GetAccreditedEducationProfile,
  GetAccreditedEducationSetting,
  GetAccreditedEducationNotification,
  GetAccreditedEducationExams,
  GetAccreditedEducationExamQuiz,
  EducationForgotPasswordPage,
  ErrorRoute404,
  LearnInvestSignup,
  LearnInvestLogin,
  InvestSyllabus,
  SyllabusSelect,
  ReadingMaterialDetails,
  VideoLectureDetails,
  Quizzes,
  QuizDetalis,
  QuizResult,
  EducationInvestProfile,
  EducationInvestSetting,
  EducationInvestNotification,
  EducationInvestExams,
  EducationInvestExamDetails,
  Education,
} from '../containers/EducationLazyLoading'
import CustomSpnniner from '../components/CustomSpnniner/CustomSpnniner'
import Loader from '../components/Loader/Loader'
import ScoutStep from '../pages/Scout/ScoutSignup/ScoutStep'
import ScrollToTopRouteChange from './ScrollToTopRouteChange'

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      previousToken: props.token,
    }
  }

  componentDidMount() {
    if (window.location.href?.includes('https://www.')) {
      window.location.href = window.location.href.replace('https://www.', 'https://')
    }
    // Add event listener on the storage token to update the redux react state
    // token when the storage token is modified.

    if (localStorage.getItem('userId') && document.location.pathname.includes('/investor')) {
      this.props.getInvestorProfile(localStorage.getItem('userId'))
    }
    window.addEventListener('storage', event => {
      if (event.key === 'token') {
        const currentStorageToken = getTokenInLocaleStorage()
        if (currentStorageToken !== this.props.token) {
          this.props.setJwtToken(currentStorageToken)
        }
      }
    })

    // Emit page event on initial page load
    emitPageEvent()
  }

  componentDidUpdate(previousProps) {
    if (this.props.location.pathname !== previousProps.location.pathname) {
      // Emit page event for any change in the path.
      emitPageEvent()
    }
  }

  render() {
    const {
      token,
      investorStatus,
      investorUser,
      startupUser,
      scoutUser,
      investorLoading,
      set_invite_code,
    } = this.props
    let userId = null
    if (token) {
      if (investorStatus) {
        if (investorUser.id) {
          userId = investorUser.id
        }
      } else {
        if (startupUser.id) {
          userId = startupUser.id
        }
        if (scoutUser?.id) {
          userId = scoutUser.id
        }
      }
    }

    ReactGA.initialize('UA-153176601-1', {
      debug: true,
      titleCase: false,
      gaOptions: {
        userId,
      },
    })

    // Update the previous token and local storage token if the token is changed
    if (token !== this.state.previousToken) {
      setTokenInLocalStorage(token)

      this.setState({
        previousToken: token,
      })
    }
    const currentToken = getTokenInLocaleStorage()
    if (currentToken) {
      let decodeTokenData = jwt_decode(currentToken)
      if (new Date().getTime() >= decodeTokenData.exp * 1000) {
        localStorage.clear()
        this.props.history.push('/startup')
      }
    }

    function RedirectToLoginStartup() {
      return <Redirect to={'/startup/onboarding'} />
    }
    function RedirectToLoginInvestor() {
      return <Redirect to={'/investor/login'} />
    }
    function RedirectToLoginScout() {
      return <Redirect to={'/scout/login'} />
    }
    function RedirectToLoginRecruit() {
      return <Redirect to={'/recruit/onboarding'} />
    }

    return (
      <div className="App">
        <Helmet>
          <title>Exitfund</title>
          <meta
            name="description"
            content="Connecting the most relevant founders and investors through data-driven intros at the pre-seed and seed stages."
          />
          <meta
            name="keywords"
            content="angel investors for startups, find angel investors, find angel investors online, angel investors search, angel investors website,
          angel investors online, how to find angel investors, technology angel investors, corporate angel investors, angel investors"
          />
        </Helmet>

        {/* <BrowserRouter> */}
        <React.Suspense fallback={<Loader />}>
          {/* <ScrollToTopRouteChange> */}
          <Switch>
            <Route exact path="/" component={NewHomePage} />
            <Route exact path="/startup" component={NewHomePage} />
            <Route exact path="/investor" component={NewHomePage} />
            <Route exact path="/scout" component={NewHomePage} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/faqs" component={Faqs} />
            <Route exact path="/blogs" component={BlogListPage} />
            <Route exact path="/blog-view/:id" component={BlogViewDetails} />
            <Route exact path="/podcasts" component={PodcastList} />
            <Route exact path="/suspend" component={Suspend} />
            <Route exact path="/podcast-view/:id" component={PodcastViewDetails} />
            <Route exact path="/education/learn-invest/" component={Education} />
            <Route exact path="/education/get-accredited/" component={Education} />
            <Route exact path="/recruit" component={Hiring} />
            <Route exact path="/recruit/login" component={LoginHiring} />
            <Route exact path="/recruit/signup" component={RegisterHiring} />
            <Route exact path="/recruit/onboarding/:step" component={RedirectToLoginRecruit} />
            <Route exact path="/recruit/startup-info" component={AboutYourStartup} />
            <Route exact path="/recruit/onboarding" component={HiringStepPage} />
            <Route exact path="/recruit/congratulations" component={Congratulations} />
            <Route exact path="/ScoutOnboarding" component={ScoutOnboarding} />

            <Route exact path="/angel/formsite" component={AngelOnboardingFormsitePage} />
            <Route exact path="/angel/typeform" component={AngelOnboardingTypeformPage} />

            <Route exact path="/login" component={LoginForm1} />
            <Route exact path="/investor/login" component={LoginInvestor} />
            <Route exact path="/startup/login" component={LoginStartup} />

            {/* <Route exact path="/startup/onboarding" component={StartupInformation} /> */}
            <StartupProtectedRoute exact path="/startup/onboarding/:step" component={StartupInformation} />
            {/* <InvestorProtectedRoute exact path="/investor/onboarding" component={InvestorStepPage} /> */}
            <InvestorProtectedRoute exact path="/investor/onboarding/:step" component={InvestorStepPage} />
            <Route exact path="/scout/onboarding/:step" component={RedirectToLoginScout} />
            <Route exact path="/scout/congratulations" component={Step06Congratulations} />
            <Route exact path="/investor/signup" component={SignUpInvestor} />
            <Route exact path="/startup/signup" component={SignUpStartup} />
            <StartupProtectedRoute exact path="/tracking/monthly-update" component={TrackingMonthlyUpdates} />
            <StartupProtectedRoute
              exact
              path="/tracking/submit-monthly-update"
              component={TrackingMonthlyUpdatesForm}
            />
            <StartupProtectedRoute
              exact
              path="/tracking/edit-monthly-update/:id"
              component={EditTrackingMonthlyUpdates}
            />
            <StartupProtectedRoute exact path="/tracking/history" component={TrackingHistoryUpdates} />
            <StartupProtectedRoute exact path="/tracking/profile/:currentTab" component={ProfileMain} />
            <StartupProtectedRoute
              exact
              path="/tracking/setting/:currentTab"
              component={ProfileSettingMain}
            />
            <StartupProtectedRoute exact path="/tracking/preview" component={TrackingPreviewInvestorView} />
            <StartupProtectedRoute exact path="/tracking/notifications" component={TrackingNotification} />
            <Route exact path="/scout/email-confirm" component={ScoutEmailConfirmPage} />
            {/* <Route exact path="/startup/signup" component={SignupFlow} /> */}
            <Route exact path="/scout/login" component={LoginScouts} />
            <Route exact path="/scout/signup" component={ScoutSignup} />

            <Route exact path="/scout/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/startup/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/investor/forgot-password" component={ForgotPasswordPage} />
            <Route exact path="/recruit/forgot-password" component={ForgotPasswordPage} />

            <Route exact path="/reset/password" component={ResetPasswordPage} />
            <Route exact path="/termsofservice" component={TermsPage} />
            <Route exact path="/policy" component={PolicyPage} />
            <StartupProtectedRoute
              exact
              path="/startup/approve/profile/:currentTab"
              component={StartupApprovedProfileMain}
            />
            <StartupProtectedRoute
              exact
              path="/startup/approve/settings/:currentTab"
              component={StartupApprovedSetting}
            />

            <StartupProtectedRoute exact path="/startup/approve/dashboard/status" component={StatusMain} />
            <StartupProtectedRoute exact path="/startup/approve/dashboard/q&a-forum" component={QAFormMain} />
            <StartupProtectedRoute
              exact
              path="/startup/approve/investors/investors-history"
              component={InvestorHistoryMain}
            />
            <StartupProtectedRoute
              exact
              path="/startup/approve/investors/investors-feedback"
              component={InvestorFeedbackMain}
            />

            <StartupProtectedRoute
              exact
              path="/startup/approve/monthly-updates"
              component={MonthlyUpdateHistory}
            />
            <StartupProtectedRoute
              exact
              path="/startup/approve/monthly-updates/submit-update"
              component={MonthlyUpdateform}
            />
            <StartupProtectedRoute
              exact
              path="/startup/approve/edit-monthly-update/:id"
              component={EditMonthlyUpdateform}
            />
            <StartupProtectedRoute
              exact
              path="/startup/approve/monthly-updates/analytics"
              component={MonthlyUpdateAnalytic}
            />
            <StartupProtectedRoute
              exact
              path="/startup/approve/notifications"
              component={StartupNotification}
            />
            <StartupProtectedRoute exact path="/startup/approve/preview" component={PreviewInvestorViewNew} />
            <StartupProtectedRoute exact path="/startup/approve/otpinput" component={OtpInput} />
            <StartupProtectedRoute
              exact
              path="/startup/approve/investor-detail/:id"
              component={InvestorDetail}
            />
            <StartupProtectedRoute
              exact
              path="/startup/approve/fundraise/invite-investors"
              component={Fundraise}
            />
            <StartupProtectedRoute
              exact
              path="/startup/approve/fundraise/invite-history"
              component={Fundraise}
            />
            <StartupProtectedRoute exact path="/startup/approve/fundraise/posts" component={Fundraise} />
            <StartupProtectedRoute exact path="/startup/approve/fundraise/post-all" component={PostDetails} />
            <StartupProtectedRoute
              exact
              path="/startup/approved/fundraise/view-history/:id"
              component={HistoryViewPost}
            />
            <StartupProtectedRoute exact path="/startup/thankyou" component={ThankYouPage} />
            {/* Scout portal Routes */}
            <ScoutProtectedRoute exact path="/scout/profile" component={ScoutProfile} />
            <ScoutProtectedRoute exact path="/scout/settings/:Tab" component={ScoutSettings} />
            <ScoutProtectedRoute
              exact
              path="/scout/settings/carry-recipient/add-recipient"
              component={ScoutSettingsAddRecipient}
            />
            <ScoutProtectedRoute exact path="/scout/invite" component={ScoutInvite} />
            <ScoutProtectedRoute exact path="/scout/deals" component={ScoutDeals} />
            <ScoutProtectedRoute exact path="/scout/referrals" component={ScoutReferrals} />
            <ScoutProtectedRoute exact path="/scout/contracts" component={ScoutContracts} />
            <ScoutProtectedRoute
              exact
              path="/scout/contracts/add-entity"
              component={ScoutContractsAddEntity}
            />
            <ScoutProtectedRoute exact path="/scout/notifications" component={ScoutNotifications} />
            <ScoutProtectedRoute exact path="/scout/invite/history" component={ScoutInviteHistory} />
            {/* Investor Routes */}
            <Route exact path="/investor/alreadydelete" component={InvestorAlreadyDeletePage} />
            <Route exact path="/investor/firstinvite" component={InvestorFirstInvitePage} />
            <Route exact path="/investor/secondinvite" component={InvestorSecondInvitePage} />
            <Route exact path="/investor/afterinvite" component={InvestorAfterInvitePage} />

            <InvestorProtectedRoute exact path="/investor/deals/:id" component={InvestorDealDetail} />
            <InvestorProtectedRoute
              exact
              path="/investor/increase-investment"
              component={IncreaseInvestment}
            />
            <InvestorProtectedRoute exact path="/investor/profile" component={InvestorProfile} />
            <InvestorProtectedRoute
              exact
              path="/investor/deals"
              component={InvestorDeals}
              statusCheck="approved"
            />
            <InvestorProtectedRoute exact path="/investor/monthly-updates/:id" component={MonthlyUpdates} />
            <InvestorProtectedRoute
              exact
              path="/investor/monthly-updates/analytics/:id"
              component={MonthlyUpdatesAnalytics}
            />
            <InvestorProtectedRoute exact path="/investor/pass-deal" component={PassDeal} />
            <InvestorProtectedRoute exact path="/investor/public-qaforum" component={PublicQAForum} />
            <InvestorProtectedRoute exact path="/investor/review-memo" component={ReviewMemo} />
            <InvestorProtectedRoute exact path="/investor/invest" component={InvestorInvestingSteps} />
            <InvestorProtectedRoute exact path="/investor/history-invested" component={InvestorHistory} />
            <InvestorProtectedRoute exact path="/investor/history-passed" component={InvestorPassedHistory} />
            <InvestorProtectedRoute
              exact
              path="/investor/another-investment"
              component={InvestorAnotherInvestment}
            />
            {/* InvestorDealDwollaFlow Urls below */}
            <InvestorProtectedRoute exact path="/investor/deal-dwolla/:id" component={InvestorDealDwolla} />
            {/* InvestorDealDwollaFlow Urls above */}
            {/* <InvestorProtectedRoute
              exact
              path="/investor/setting/change-password"
              component={InvestorChangePassword}
            /> */}
            {/* <InvestorProtectedRoute exact path="/investor/setting/banking" component={InvestorSetting} /> */}
            {/* <InvestorProtectedRoute
              exact
              path="/investor/setting/email-notification"
              component={InvestorEmailNotification}
            /> */}
            {/* <InvestorProtectedRoute
              exact
              path="/investor/setting/tax-information"
              component={InvestorSettingTaxes}
            /> */}
            {/* <InvestorProtectedRoute exact path="/investor/setting/kyc" component={InvestorKyc} /> */}
            <InvestorProtectedRoute
              exact
              path="/investor/settings/:currentTab"
              component={InvestorSettings}
            />
            <InvestorProtectedRoute exact path="/investor/complete-kyc" component={CompleteKyc} />
            <InvestorProtectedRoute
              exact
              path="/investor/doc-verification"
              component={BankingDocVerification}
            />
            <InvestorProtectedRoute exact path="/investor/banking-profile" component={BankingProfileInfo} />
            <InvestorProtectedRoute exact path="/investor/dwolla-email" component={EnterEmail} />
            <InvestorProtectedRoute exact path="/investor/tour" component={InvestorTour} />
            <InvestorProtectedRoute exact path="/investor/tour/deals" component={InvestorTour} />
            <InvestorProtectedRoute exact path="/investor/tour/history" component={InvestorTourHistory} />
            <InvestorProtectedRoute exact path="/investor/tour/karma" component={InvestorTourKarma} />
            <InvestorProtectedRoute exact path="/investor/tour/profile" component={InvestorTourProfile} />
            <InvestorProtectedRoute exact path="/investor/notifications" component={InvestorNotifications} />
            <InvestorProtectedRoute exact path="/investor/tour/deals" component={InvestorTour} />
            <InvestorProtectedRoute exact path="/investor/tour/history" component={InvestorTourHistory} />
            <InvestorProtectedRoute exact path="/investor/thankyou">
              <ThankYouPage />
            </InvestorProtectedRoute>
            <InvestorProtectedRoute path="/investor/accreditation">
              <Step12AccreditionStatus />
            </InvestorProtectedRoute>
            {/* Startup Routes */}
            {/* <Route exact path="/startup/alreadydelete" component={StartupAlreadyDeletePage} /> */}
            {/* <StartupProtectedRoute path="/startup/dashboard">
              <StartupDashboard />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/feedback">
              <StartupFeedbackPage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/investor">
              <StartupInvestorCard />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/profile/edit">
              <StartupProfileEditPage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/preview">
              <StartupPreviewPage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/profile">
              <StartupProfilePage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/editsector">
              <SectorProfilePage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/settings">
              <StartupSettingsPage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/cofounder">
              <CoFounderPage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/refer">
              <StartupReferPage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/notifications">
              <StartupNotificationsPage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/privacy">
              <StartupPrivacyPage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/account">
              <StartupAccountPage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/seemore">
              <StartupSeeMorePage />
            </StartupProtectedRoute>
            <Route exact path="/startup/changepassword" component={StartupChangePWPage} />
            <Route exact path="/startup/cpw" component={StartupMobilePWPage} />
            <StartupProtectedRoute path="/startup/delete">
              <StartupDeletePage />
            </StartupProtectedRoute>
            <StartupProtectedRoute path="/startup/deleteconfirm">
              <StartupDeleteConfirmPage />
            </StartupProtectedRoute>

            <Route exact path="/startup/logout" component={StartupLogOutPage} /> */}
            {/* Education portal learn invest routes */}
            <Route exact path="/education/learn-invest/signup" component={LearnInvestSignup} />
            <Route exact path="/education/learn-invest/login" component={LearnInvestLogin} />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/syllabus"
              component={InvestSyllabus}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/select-syllabus"
              component={SyllabusSelect}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/reading-material/:syllabusId"
              component={ReadingMaterialDetails}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/video-lecture/:syllabusId"
              component={VideoLectureDetails}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/quizzes/:syllabusId"
              component={Quizzes}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/quiz-detail/:quizId"
              component={QuizDetalis}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/quiz-result/:quizId"
              component={QuizResult}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/profile"
              component={EducationInvestProfile}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/setting"
              component={EducationInvestSetting}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/notifications"
              component={EducationInvestNotification}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/exams"
              component={EducationInvestExams}
            />
            <LearnInvestProtectedRoute
              exact
              path="/education/learn-invest/exams/:examId"
              component={EducationInvestExamDetails}
            />

            {/* Education portal get-accredited routes */}
            <Route exact path="/education/get-accredited/signup" component={GetAccreditedSignup} />
            <Route exact path="/education/get-accredited/login" component={GetAccreditedLogin} />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/syllabus"
              component={GetAccreditedSyllabus}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/select-syllabus"
              component={GetAccreditedSyllabusSelect}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/reading-material/:syllabusId"
              component={GetAccreditedReadingMaterialDetails}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/video-lecture/:syllabusId"
              component={GetAccreditedVideoLectureDetails}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/quizzes/:syllabusId"
              component={GetAccreditedQuizzes}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/quiz-detail/:quizId"
              component={GetAccreditedQuizDetalis}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/quiz-result/:quizId"
              component={GetAccreditedQuizResult}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/profile"
              component={GetAccreditedEducationProfile}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/setting"
              component={GetAccreditedEducationSetting}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/notifications"
              component={GetAccreditedEducationNotification}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/exams"
              component={GetAccreditedEducationExams}
            />
            <Series65ProtectedRoute
              exact
              path="/education/get-accredited/exams/:examId"
              component={GetAccreditedEducationExamQuiz}
            />
            <Route
              exact
              path={'/education/learn-invest/forgot-password'}
              component={EducationForgotPasswordPage}
            />
            <Route
              exact
              path={'/education/get-accredited/forgot-password'}
              component={EducationForgotPasswordPage}
            />
            <Route exact path={'/education/email-confirm'} component={EducationEmailConfirmPage} />
            <Route
              exact
              path="/startup/:personalInviteCode/:startupName/monthly-updates"
              component={StartupMonthlyUpdates}
            />
            <Route
              exact
              path="/:linkType/:startupName/:inviteCode/:inviterInviteCode"
              inviteCode={set_invite_code}
              component={StartupDetailByLinkType}
            />
            <Route
              exact
              path="/startup/:startupName/:inviteCode"
              inviteCode={set_invite_code}
              component={StartupDetail}
            />
            {/* Error */}
            <Route component={ErrorRoute404} />
          </Switch>
          {/* </ScrollToTopRouteChange> */}
        </React.Suspense>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  startupPage: state.home.startupPage,
  investorPage: state.home.investorPage,
  scoutPage: state.home.scoutPage,
  trackingPage: state.home.trackingPage,
  token: state.auth.token,
  investorStatus: state.auth.investor,
  startupUser: state.auth.startupUser,
  investorUser: state.auth.user,
  scoutUser: state?.auth?.scoutUser,
  set_invite_code: state.StratupApprovedFlowControlReducers.set_invite_code,
  investorLoading: state?.investorflowControl.loading,
})

const mapDispatchToProps = dispatch => ({
  setJwtToken: token => {
    dispatch(setJwtToken(token))
  },
  publicLinkActivation: data => {
    dispatch(publicLinkActivation(data))
  },
  getInvestorProfile: data => {
    dispatch(getInvestorProfile(data))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
