import React, { useEffect } from 'react'
import StartupSlideDeckUploadModal from '../StartupSlideDeckUpload/StartupSlideDeckUploadModal'

import './SlideDeckPage.scss'
import pdfIcon from '../../../assets/images/pdf.png'
import pdfIconNew from '../../../assets/images/on_boarding_imgs/Vector.svg'

import { connect, useDispatch, useSelector } from 'react-redux'
import { Button, Col, Container, Row } from 'react-bootstrap'

import { message, notification, Spin, Steps, Upload } from 'antd'
import api from '../../../utils/api'
import 'antd/dist/antd.css'
import { ArrowRightOutlined, DeleteOutlined } from '@ant-design/icons'

import {
  nextStepStartup,
  prevStepStartup,
  isClickedToShowSlideDeckForm,
  showSlideDeckUploadModal,
  slideDeckUploadFailed,
  hideSlideDeckUploadModal,
  SaveAndExitClickedReset,
} from '../../../redux/startupFlowControl/startupFlowControlActions'

import {
  updateLocalStartupUser,
  fetchStartupFileUpload,
  displayFileName,
  removeFilename,
  removeSlideDeck,
  didNotProvideSlideDeck,
  sendWaitListEmail,
  fetchStartupUserLogout,
  fetchStartupUserInfoUpdate,
} from '../../../redux/auth/authActions'
import { handleNameFromUrl } from '../../../utils/helper'

const SlideDeckPage = ({
  updateUserInfoFailed,
  stepStartup,
  showSlideDeckForm,
  isClicked,
  nextStepStartup,
  prevStepStartup,
  slide_deck,
  fileUploaded,
  uploadPDF,
  updateLocalStartupUser,
  fetchStartupFileUpload,
  displayFileName,
  removeFilename,
  removeSlideDeck,
  fileName,
  didNotProvideSlideDeck,
  sendWaitListEmail,
  startupUser,
  showSlideDeckUploadModal,
  slideDeckUploadFailed,
  hideSlideDeckUploadModal,
  slideDeckUploadFile,
  onCountinueClick,
  fetchStartupUserInfoUpdate,
}) => {
  const { saveAndExitClicked } = useSelector(state => state.startupflowControl)
  const { loading } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [loader, setLoader] = React.useState(false)
  const [fileErr, setFileErr] = React.useState('')
  /**
   * Handle the processing of startup user slide deck information.
   * If the startup user's slide deck is not null, extract the filename from the AWS URL,
   * and update the startup user information with the filename.
   */
  useEffect(() => {
    if (startupUser?.slide_deck !== null) {
      const splittedArray = startupUser?.slide_deck?.split('-')
      const awsIndex = splittedArray?.findIndex(item => item.includes('amazonaws.com'))
      const filename = splittedArray?.slice(awsIndex + 1).join('-')

      dispatch({
        type: 'RECEIVE_STARTUP_USER_INFO_UPDATE',
        payload: { ...startupUser, fileName: filename },
      })
    }
  }, [])

  /**
   * Handle file change event for the startup user's slide deck.
   * If the loading state or loader state is true, return early.
   * Check the size of the file and show a warning if it exceeds the limit.
   * Set the loader state to true, send the file to the server for processing, and update the startup user information.
   */
  const handleFileChange = e => {
    if (loading || loader) {
      return
    }
    let size = parseFloat(e[0].size / (1024 * 1024)).toFixed(2)
    if (size > 15) {
      return message.warn('This file size is not supported.')
    }
    setLoader(true)
    if (e.length > 0) {
      api.sendFile(`${process.env.REACT_APP_API_URL}/startup/mime-files`, e[0], 'application').then(res => {
        setLoader(false)
        if (res.result) {
          dispatch({
            type: 'RECEIVE_STARTUP_USER_INFO_UPDATE',
            payload: { ...startupUser, slide_deck: res.url },
          })
          // startupOnClick(e[0])
          displayFileName(e[0].name)
        } else {
          notification.error({
            message: 'Someting Went Wrong',
            description: res.error,
          })
        }
      })
    }
  }

  /**
   * Handle the click event for the startup user's slide deck.
   * Determine the onboarding step, upload the file, and perform cleanup actions.
   * If successful, invoke the isClicked and GetFilename functions; otherwise, remove the filename.
   */
  const startupOnClick = file => {
    let onboarding_step = 8
    fetchStartupFileUpload(file, 'application', 'slide_deck', onboarding_step)
      .then(success => {
        if (success) {
          isClicked()
          GetFilename(file)
        } else {
          removeFilename()
        }
      })
      .then(() => {
        if (saveAndExitClicked) {
          localStorage.clear()
          window.location.href = '/startup/login'
          dispatch(fetchStartupUserLogout())
        }
      })
  }

  /**
   * Get the filename from the provided URL and display it using the displayFileName function.
   * @param {Object} url - The URL object containing the file name.
   * @returns {string} - An empty string.
   */
  const GetFilename = url => {
    if (url) {
      displayFileName(url.name)
    }
    return ''
  }

  /**
   * Handle the click event to continue to the next step.
   * Update the onboarding step to 8 and proceed to the next step if the update is successful.
   */
  const handleContinue = () => {
    fetchStartupUserInfoUpdate({ onboarding_step: 8, slide_deck: startupUser?.slide_deck }).then(success => {
      if (success) {
        onCountinueClick()
      }
    })
  }

  /**
   * Handle the remove file event.
   * Prevent the default behavior, remove the slide deck, and remove the filename.
   * @param {Object} e - The event object.
   */
  const removeFile = e => {
    e.preventDefault()
    removeSlideDeck().then(() => {
      removeFilename()
    })
  }

  /**
   * Handle the drop event for file upload.
   * Prevent the default behavior and call the handleFileChange function with the dropped files.
   * @param {Object} e - The event object.
   */
  const handleDrop = e => {
    e.preventDefault()
    handleFileChange(e.dataTransfer.files)
  }

  /**
   * Handle the drag enter event.
   * Prevent the default behavior.
   * @param {Object} e - The event object.
   */
  const handleDragEnter = e => {
    e.preventDefault()
  }

  /**
   * Handle the drag leave event.
   * Prevent the default behavior.
   * @param {Object} e - The event object.
   */
  const handleDragLeave = e => {
    e.preventDefault()
  }

  /**
   * Handle the drag over event.
   * Prevent the default behavior.
   * @param {Object} e - The event object.
   */
  const handleDragOver = e => {
    e.preventDefault()
  }

  // Handle the input change event.
  const handleInputChange = () => {}

  /**
   * Handle cleanup actions when saveAndExitClicked is true.
   * Invoke the startupOnClick function with appropriate parameters and reset the saveAndExitClicked state.
   */
  useEffect(() => {
    if (saveAndExitClicked) {
      startupOnClick(false, true)
      dispatch(SaveAndExitClickedReset())
    }
  }, [saveAndExitClicked])

  const { Step } = Steps

  return (
    <div>
      {/*** Heading is here ***/}
      <Container className="mb-3">
        <Row>
          <Col lg={7} md={8} className="mx-auto">
            <h4 className="heading-question">
              Please attach your <span className="font-weight-bold">slide deck.</span>
            </h4>
          </Col>
        </Row>
      </Container>

      {/*** PDF Set is here ***/}
      <Container className="mb-3">
        <Row>
          <Col lg={7} md={8} className="mx-auto">
            <div className="button-container text-center">
              {fileName ? null : (
                <form>
                  <div
                    onDrop={e => handleDrop(e)}
                    onDragOver={e => handleDragOver(e)}
                    onDragEnter={e => handleDragEnter(e)}
                    onDragLeave={e => handleDragLeave(e)}
                    onChange={handleInputChange}
                  >
                    <label className="pdf-attache-set" htmlFor="file">
                      <img width="80px" height="80px" src={pdfIconNew} alt="pdf" />
                      <p className="upload-pdf">Upload only PDF</p>
                      <p className="upload-text">Drag and drop your pdf file here or</p>

                      <div className="pdf-button-container">
                        {loading || loader ? (
                          <Spin />
                        ) : (
                          <>
                            <h4 className="title-slide text-white">Choose File</h4>
                            <div style={{ textAlign: 'center', paddingBottom: '5px' }}>
                              <span className="pdf-subtitle">PDF only (Max 15 MB)</span>
                              <input
                                onChange={e => {
                                  handleFileChange(e.target.files)
                                }}
                                type="file"
                                className="input-file"
                                id="file"
                                accept="application/pdf"
                              ></input>
                            </div>
                          </>
                        )}
                      </div>
                    </label>
                  </div>
                </form>
              )}
              <p className="text-danger mt-2">{fileErr}</p>
              {fileName ? (
                <div className="d-flex align-items-center pdf-upload-container">
                  <div className="d-flex align-items-center remove-wrapper">
                    <img className="pdf-icon" width="70px" src={pdfIcon} alt="pdf" />
                    <span className="file-name">{handleNameFromUrl(fileName)}</span>
                    <DeleteOutlined onClick={removeFile} />
                  </div>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>

      {/* New Continue buttons */}
      <section className="bottomBtns">
        <Container className="">
          <Row>
            <Col md={4} xs={12} className="text-center mx-auto">
              <Button
                onClick={handleContinue}
                // disabled={!isDoneButtonEnabled || fileErr !== ''}
                disabled={loading || loader || fileErr !== '' || fileName === null || fileName === ''}
                className="continueBtnOnboard btn-block"
              >
                Continue <ArrowRightOutlined />
              </Button>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="footer-button d-none">
        {/* <div className="buttons-container" /> */}
        <Button onClick={onCountinueClick} className="continueBtnOnboard btn-block d-none">
          Continue <ArrowRightOutlined />
        </Button>
        <div className="group-buttons">
          <Button onClick={prevStepStartup} className="back-button">
            Back
          </Button>

          <Button onClick={onCountinueClick} className="continueBtnOnboard btn-block">
            Continue <ArrowRightOutlined />
          </Button>
        </div>
      </div>

      {slideDeckUploadFile && <StartupSlideDeckUploadModal file={slideDeckUploadFile} />}
    </div>
  )
}

const mapStateToProps = state => ({
  stepStartup: state.startupflowControl.stepStartup,
  startupUser: state.auth.startupUser,
  showSlideDeckForm: state.startupflowControl.showSlideDeckForm,
  slide_deck: state.auth.startupUser.slide_deck,
  fileName: state.auth.startupUser.fileName,
  updateUserInfoFailed: state.investorflowControl.updateUserInfoFailed,
  fileUploaded: state.startupflowControl.fileUploaded,
  investor: state.auth.investor,
  slideDeckUploadFile: state.startupflowControl.slideDeckUploadModal.file,
})

const mapDispatchToProps = dispatch => ({
  isClicked: () => dispatch(isClickedToShowSlideDeckForm()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  prevStepStartup: () => dispatch(prevStepStartup()),

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },
  sendWaitListEmail: status => {
    dispatch(sendWaitListEmail(status))
  },
  fetchStartupUserInfoUpdate: changedProperties => {
    return dispatch(fetchStartupUserInfoUpdate(changedProperties))
  },

  uploadPDF: () => {
    const action = { type: 'PITCH_DECK_UPLOAD_SUCCESS' }
    dispatch(action)
  },

  fetchStartupFileUpload: (file, fileType, fieldName) => {
    return dispatch(fetchStartupFileUpload(file, fileType, fieldName))
  },

  displayFileName: file => dispatch(displayFileName(file)),
  removeFilename: () => dispatch(removeFilename()),
  didNotProvideSlideDeck: () => dispatch(didNotProvideSlideDeck()),
  removeSlideDeck: () => dispatch(removeSlideDeck()),
  showSlideDeckUploadModal: file => dispatch(showSlideDeckUploadModal(file)),
  slideDeckUploadFailed: () => dispatch(slideDeckUploadFailed()),
  hideSlideDeckUploadModal: () => dispatch(hideSlideDeckUploadModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SlideDeckPage)
