import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux'
import { Button, InputNumber, DatePicker } from 'antd'
import moment from 'moment'
import './HistoryInvestedFilterToggle.scss'
import { debounce } from 'lodash'
import { fetchInvestmentHistory } from '../../../../redux/investorFlowControl/investorFlowControlActions'

const HistoryInvestedFilterToggle = props => {
  const dispatch = useDispatch()
  const { setIsFilterSelected } = props

  const [filterMethod, setFilterMethod] = useState('all')
  const [data, setData] = useState({ page_num: 1, page_size: 10, filter_method: 'all' })
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedAmount, setSelectedAmount] = useState({ min: null, max: null })
  const [dataUpdated, setDataUpdated] = useState(false)

  const handleAmountChange = debounce((field, value) => {
    const newValue = value.toString().replace(/\s+/g, '')
    setSelectedAmount(prev => ({
      ...prev,
      [field]: parseFloat(newValue),
    }))
    let newData = { ...data }
    newData.min_amt = field == 'min' ? parseFloat(newValue) : selectedAmount?.min
    newData.max_amt = field == 'max' ? parseFloat(newValue) : selectedAmount?.max
    newData.page_num = 1
    newData.page_size = data.page_size

    if (filterMethod == 'date' || filterMethod == 'date&amount') {
      setFilterMethod('date&amount')
      newData.filter_method = 'date&amount'
    } else {
      setFilterMethod('amount')
      newData.filter_method = 'amount'
    }
    setData(newData)
    setDataUpdated(true)
  }, 1000)

  // Hit the function on date change
  const onChangeDate = event => {
    let newData = { ...data }
    newData.page_num = 1
    if (event) {
      setFilterMethod('date')
      setSelectedDate(event)
      newData.filter_method = 'date'
      newData.date = moment(event).format('YYYY-MM-DD')
    } else {
      setSelectedDate(null)
      newData.filter_method = 'all'
      delete newData.date
    }

    setData(newData)
    setDataUpdated(true)
  }

  const onChangeDateWithExistingAmount = event => {
    let newData = { ...data }
    newData.page_num = 1
    if (event) {
      setSelectedDate(event)
      setFilterMethod('date&amount')
      newData.filter_method = 'date&amount'
      newData.date = moment(event).format('YYYY-MM-DD')
    } else {
      newData.filter_method = 'amount'
      setFilterMethod('amount')
      setSelectedDate(null)
    }

    setData(newData)
    setDataUpdated(true)
  }

  // Hit the function on click All Filter
  const onAll = () => {
    // window.location.reload()
    let newData = { ...data }
    setFilterMethod('all')
    setSelectedDate(null)
    setSelectedAmount({ min: null, max: null })
    newData.filter_method = 'all'

    setData(newData)
    setDataUpdated(true)
  }

  const handleFilter = () => {
    if (dataUpdated) {
      props.fetchInvestmentHistory(data, res => {
        setIsFilterSelected(false)
      })
    } else {
      setIsFilterSelected(false)
    }
  }

  return (
    <div className="modal-wrap-investor-history">
      <div className="modal-details">
        <div className="invest-section">
          <div className="btn-qa-wrapper buttonWrapperHistory">
            <Button className="qa-btn-all" onClick={onAll}>
              All
            </Button>
            <DatePicker
              className="datePickerHistory"
              value={selectedDate}
              onChange={
                filterMethod == 'amount' || filterMethod == 'date&amount'
                  ? onChangeDateWithExistingAmount
                  : onChangeDate
              }
              format={'YYYY-MM-DD'}
            />
          </div>
          <div className="investedAmtFilter">
            <InputNumber
              className="inputs-box"
              placeholder={'$ Min'}
              formatter={value => value && `$ ${value}`}
              min={0}
              parser={value => value.replace('$', '').replace(/\s+/g, '')}
              value={selectedAmount?.min}
              onChange={value => handleAmountChange('min', value)}
            />
            {' - '}
            <InputNumber
              className="inputs-box"
              placeholder={'$ Max'}
              formatter={value => value && `$ ${value}`}
              min={0}
              parser={value => value.replace('$', '').replace(/\s+/g, '')}
              value={selectedAmount?.max}
              onChange={value => handleAmountChange('max', value)}
            />
          </div>
        </div>
        <Button
          className="button-wrap apply"
          onClick={() => {
            handleFilter()
          }}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  fetchInvestmentHistory: (data, onSuccess) => {
    return dispatch(fetchInvestmentHistory(data, onSuccess))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoryInvestedFilterToggle))
