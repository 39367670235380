import { createGuestIdentifier } from '../metrics/identifiers'
import { requireNonEmpty } from '../utils/general'
import { detectedBrowser } from './detectedBrowser'

let isSegmentAnalyticsReady = false

window.analytics.ready(() => {
  isSegmentAnalyticsReady = true
})

// This function should only be used internally in the metrics directory
// Most likely any emissions should be from metricsEmitter.js instead.
export function emitEvent(eventName, eventProperties) {
  requireNonEmpty(eventName, 'Event name should be non-empty for event emission.')
  requireNonEmpty(eventProperties, 'Event properties should be non-empty for event emission.')
  const augmentedProperties = {
    ...eventProperties,
    ...detectedBrowser,
    eventSource: 'frontend',
  }

  // Check if user method is available yet. We generally should never be firing
  // events before segment analytics is loaded, but just being defensive here.
  if (!isSegmentAnalyticsReady) {
    console.error(
      `Unable to emit event with event name [${eventName}] and properties ` +
        `${JSON.stringify(eventProperties)} to analytics as segment analytics is not ` +
        `ready and initialized yet. Most likely this occurs when the user has certain ad blockers on.`,
    )
    return
  }

  const currentLoggedInUniversalUserId = window.analytics.user().id()
  // Currently just hacking this to construct a for the debugging message
  // for more easily understanding what type of user is the event being emitted for
  // It would be ideal for the anonymous id for segment to be overridden by us
  // with a UUID and then constructing the guest id for use in the analytics.identify() method.
  const currentUniversalUserIdString =
    currentLoggedInUniversalUserId === null
      ? createGuestIdentifier(window.analytics.user().anonymousId())
      : currentLoggedInUniversalUserId

  window.analytics.track(eventName, augmentedProperties, null, () => {
    // console.log(
    //   `Successfully emitted event with name [${eventName}] with identity ` +
    //     `[${currentUniversalUserIdString}] with event properties: ${JSON.stringify(augmentedProperties)}`,
    // )
  })
}

export function page() {
  window.analytics.page(null, {
    ...detectedBrowser,
    eventSource: 'frontend',
  })
}

export function identify(uniqueUserId, identificationProperties) {
  requireNonEmpty(uniqueUserId, 'Unique user id should be non-empty for analytics identification.')
  requireNonEmpty(
    identificationProperties,
    'Identification properties should be non-empty for analytics identification.',
  )

  // Check if segment analytics is available yet. We generally should never be identifying
  // users before segment analytics is loaded, but just being defensive here.
  if (!isSegmentAnalyticsReady) {
    console.error(
      `Unable to identify user with user id [${uniqueUserId}] and properties ` +
        `${JSON.stringify(identificationProperties)} to analytics as segment analytics is not ` +
        `ready and initialized yet. Most likely this occurs when the user has certain ad blockers on.`,
    )
    return
  }

  window.analytics.identify(uniqueUserId, identificationProperties, null, () => {
    // console.log(
    //   `Successfully identified user with id [${uniqueUserId}] ` +
    //     `with identification properties: ${JSON.stringify(identificationProperties)}`,
    // )
  })
}

export function resetIdentity() {
  // Check if segment analytics is available yet. We generally should never be identifying
  // users before segment analytics is loaded, but just being defensive here.
  if (!isSegmentAnalyticsReady) {
    console.error(
      'Unable to reset identity for user as segment analytics is not ready and initialized yet.' +
        ' Most likely this occurs when the user has certain ad blockers on.',
    )
    return
  }

  window.analytics.reset()
}
