import React from 'react'

import { STARTUP_IDENTIFY_CARD_DATA, INVESTOR_IDENTIFY_CARD_DATA } from './IdentifyTagsData'

import { connect } from 'react-redux'

import '../TrendingCardList/TrendingCardList.scss'
import './IdentifyTagsData.scss'

import {
  prevStepInvestor,
  nextStepInvestor,
} from '../../redux/investorFlowControl/investorFlowControlActions'

import { prevStepStartup, nextStepStartup } from '../../redux/startupFlowControl/startupFlowControlActions'

import { updateLocalInvestorUser, updateLocalStartupUser } from '../../redux/auth/authActions'
import { QuestionCircleOutlined, QuestionOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

const IdentifyTagsList = ({
  investorUser,
  startupUser,
  startup,
  updateLocalStartupUser,
  updateLocalInvestorUser,
}) => {
  // Determine the progress sectors based on whether it's for a startup or an investor
  let progress_sectors = startup ? STARTUP_IDENTIFY_CARD_DATA : INVESTOR_IDENTIFY_CARD_DATA

  // Get the tags based on whether it's for a startup or an investor
  const tags = startup ? startupUser.tags : investorUser.tags

  // Create a copy of the tags for modification
  const progressCopy = tags?.slice()

  // Check if the maximum number of milestones is selected
  const maxMilestonesSelected = tags?.length === 4

  /**
   * Handle the click event for a progress card.
   * @param {string} id - The ID of the progress card.
   */
  const handleClick = id => {
    let x = id
    if (!tags?.includes(x)) {
      progressCopy && progressCopy.push(x)
    } else {
      if (tags?.includes(x)) {
        const index = progressCopy?.findIndex(element => element === x)
        progressCopy && progressCopy.splice(index, 1)
      }
    }

    if (startup) {
      updateLocalStartupUser({ tags: progressCopy })
    } else {
      updateLocalInvestorUser({ tags: progressCopy })
    }
  }

  // Generate the progress card components based on the progress sectors
  const cardComponent = progress_sectors.map((progress, i) => {
    const text = progress.title
    const IdentityTags = progress.image
    const IdentityTagsActive = progress.ActiveImg

    const selectedCard = startup ? startupUser.tags : investorUser.tags
    return (
      <div
        className={selectedCard?.includes(text) ? 'selected2 progress-card tc dib ' : 'progress-card tc dib'}
        key={i}
        onClick={event => {
          if (!maxMilestonesSelected || (maxMilestonesSelected && tags.includes(progress.title))) {
            handleClick(progress.title)
          }
        }}
      >
        <div className={selectedCard?.includes(text) ? 'transparent-selection-layer-milestone' : null}></div>
        <div className="d-flex align-items-center justify-content-center innerMilestoneCard position-relative w-100">
          {/* <IdentityTags /> */}
          {/* {tags.includes(progress.title ? <img src={IdentityTags} /> : <img src={IdentityTagsActive} />)} */}
          {selectedCard?.includes(text) ? <img src={IdentityTags} /> : <img src={IdentityTagsActive} />}

          <h3
            className={
              selectedCard?.includes(text) ? 'selected-sector title-trending-card' : 'title-trending-card'
            }
            style={maxMilestonesSelected && !tags.includes(progress.title) ? { color: '' } : null}
          >
            {progress.title}
          </h3>

          {startup && text === 'Impact Startup' && (
            <Tooltip
              placement="right"
              title="Impact startups are driven by at least one of the UN’s official Sustainable Development Goals (SDGs). See the UN’s full definition for more details."
            >
              {/* <QuestionCircleOutlined /> */}
              <QuestionOutlined />
            </Tooltip>
          )}
        </div>
      </div>
    )
  })

  return (
    <div className="d-md-flex d-sm-inline-flex flex-wrap justify-content-center card-component-sectors milestones-container2">
      {cardComponent}
    </div>
  )
}

const mapStateToProps = state => ({
  updateUserInfoFailed: state.investorflowControl.updateUserInfoFailed,
  investor: state.auth.investor,
  startup: state.auth.startup,
  company: state.auth.user.company,
  investorUser: state.auth.user,
  startupUser: state.auth.startupUser,
  updateStartupUserInfoFailed: state.startupflowControl.updateUserInfoFailed,
})

const mapDispatchToProps = dispatch => ({
  updateLocalInvestorUser: changedProperties => {
    dispatch(updateLocalInvestorUser(changedProperties))
  },

  updateLocalStartupUser: changedProperties => {
    dispatch(updateLocalStartupUser(changedProperties))
  },

  nextStepInvestor: () => dispatch(nextStepInvestor()),
  prevStepInvestor: () => dispatch(prevStepInvestor()),
  nextStepStartup: () => dispatch(nextStepStartup()),
  prevStepStartup: () => dispatch(prevStepStartup()),
})

export default connect(mapStateToProps, mapDispatchToProps)(IdentifyTagsList)
