import api from '../../utils/api'
import { fetchScoutUserLogout } from '../auth/authActions'
import * as actionTypes from './actionTypes'

// Action to set email confiremed status
export const setConfirmedEmail = () => ({
  type: actionTypes.SET_SCOUT_CONFIRMED_EMAIL,
})

// Action to indicate that the "Save and Exit" button was clicked.
export const SaveAndExitClicked = data => ({
  type: actionTypes.Save_And_Exit_Clicked,
  payload: data,
})

// Action to indicate that the "Save and Exit" button was reset.
export const SaveAndExitClickedReset = data => ({
  type: actionTypes.Save_And_Exit_Clicked_Reset,
  payload: data,
})

// Action to go next step
export const nextStep = () => ({
  type: actionTypes.NEXT_STEP,
})

// Action to go next scout step
export const nextStepScout = () => ({
  type: actionTypes.NEXT_STEP_SCOUT,
})

// Action to go previous step
export const prevStep = () => ({
  type: actionTypes.PREV_STEP,
})

// Action to go previous scout step
export const prevStepScout = () => ({
  type: actionTypes.PREV_STEP_SCOUT,
})

// Action to reset initial scout step
export const initialScoutState = () => ({
  type: actionTypes.RESET_INITIAL_SCOUT_STATE,
})

// Action to indicating a scout signup failed.
export const scoutSignupFailed = () => ({
  type: actionTypes.SCOUT_SIGNUP_FAILED,
})

// Action to indicating a scout login failed.
export const scoutLoginFailed = () => ({
  type: actionTypes.SCOUT_LOGIN_FAILED,
})

// Action to update scout step
export const updateScoutStep = stepScout => ({
  type: actionTypes.UPDATE_SCOUT_STEP,
  payload: stepScout,
})

// Action to reset initial scout step
export const initialState = () => ({
  type: actionTypes.RESET_INITIAL_STATE,
})

// Action to return homepage
export const toggleLogoToHome = () => ({
  type: actionTypes.RETURN_HOME_PAGE,
})

// Action to fetch profile data of the scout users
export const fetchProfileData = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_SCOUT_PROFILE_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/scout/profile-data`)
      .then(res => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_PROFILE_SUCCESS,
          payload: res?.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_PROFILE_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to edit profile data of the scout users
export const editProfileData = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.EDIT_SCOUT_PROFILE_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/profile-data`, { ...data })
      .then(res => {
        dispatch({
          type: actionTypes.EDIT_SCOUT_PROFILE_SUCCESS,
          payload: res?.data,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.EDIT_SCOUT_PROFILE_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to upload the profile data of the scout users
export const uploadProfilePicture = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPLOAD_SCOUT_PROFILE_PICTURE_INIT,
    })
    await api
      .postwithFormData(`${process.env.REACT_APP_API_URL}/scout/mime-files`, data)
      .then(res => {
        dispatch({
          type: actionTypes.UPLOAD_SCOUT_PROFILE_PICTURE_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPLOAD_SCOUT_PROFILE_PICTURE_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to update the password
export const updatePassword = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.UPDATE_SCOUT_PASSWORD_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/update-password`, { ...data })
      .then(res => {
        if (res?.result) {
          dispatch({
            type: actionTypes.UPDATE_SCOUT_PASSWORD_SUCCESS,
            payload: res?.data,
          })
          onSuccess()
        } else {
          dispatch({
            type: actionTypes.UPDATE_SCOUT_PASSWORD_FAILED,
          })
          onError()
        }
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_SCOUT_PASSWORD_FAILED,
        })
        onError()
        throw error?.response?.error
      })
  }
}

// Action to get emailnotificatins data
export const getEmailNotifications = onSuccess => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_SCOUT_EMAIL_NOTIFICATIONS_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/scout/email-notification`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_SCOUT_EMAIL_NOTIFICATIONS_SUCCESS,
          payload: res?.data,
        })
        onSuccess && onSuccess(res?.data)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_SCOUT_EMAIL_NOTIFICATIONS_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to set email notifications
export const setEmailNotifications = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_SCOUT_EMAIL_NOTIFICATIONS_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/email-notification`, data)
      .then(res => {
        dispatch({
          type: actionTypes.SET_SCOUT_EMAIL_NOTIFICATIONS_SUCCESS,
          payload: res?.data,
        })
        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SET_SCOUT_EMAIL_NOTIFICATIONS_FAILED,
        })
        onFail && onFail(error?.response?.data?.error || error?.message)
        throw error?.response?.data?.error
      })
  }
}

// Action to Invite users in bulk via email
export const bultInvite = (data, onSuccess, onError) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.BULK_EMAIL_INVITE_SCOUT_INIT,
    })
    await api
      .postwithFormData(`${process.env.REACT_APP_API_URL}/scout/bulk-invite`, data)
      .then(res => {
        if (res?.result) {
          dispatch({
            type: actionTypes.BULK_EMAIL_INVITE_SCOUT_SUCCESS,
            payload: res,
          })
          onSuccess(res?.message)
        } else {
          onError(res?.error)
        }

        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.BULK_EMAIL_INVITE_SCOUT_FAILED,
        })
        onError(error?.response?.error)
        throw error?.response?.error
      })
  }
}

// Action for sending email invites to scouts
export const emailInvite = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.EMAIL_INVITE_SCOUT_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/invite`, data)
      .then(res => {
        dispatch({
          type: actionTypes.EMAIL_INVITE_SCOUT_SUCCESS,
          payload: res?.data,
        })
        onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.EMAIL_INVITE_SCOUT_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action for getting referrals for scout startups
export const getScoutStartupsReferrals = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_SCOUT_STARTUP_REFERRALS_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/referrals`, data)
      .then(res => {
        dispatch({
          type: actionTypes.GET_SCOUT_STARTUP_REFERRALS_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_SCOUT_STARTUP_REFERRALS_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action for getting referrals for scout investors
export const getScoutInvestorsReferrals = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_SCOUT_INVESTOR_REFERRALS_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/referrals`, data)
      .then(res => {
        dispatch({
          type: actionTypes.GET_SCOUT_INVESTOR_REFERRALS_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_SCOUT_INVESTOR_REFERRALS_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to fetch unread notifications count
export const fetchUnreadNotificationCount = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/notification-dropdown`, { is_clicked: false })
      .then(res => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATION_COUNT_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to fetch unread notifications
export const fetchUnreadNotifications = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATIONS_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/notification-dropdown`, { is_clicked: true })
      .then(res => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATIONS_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_UNREAD_NOTIFICATIONS_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to clear all notifications
export const clearScoutNotifications = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CLEAR_SCOUT_NOTIFICATIONS_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/notification-dropdown`, { is_clicked: true, clear: true })
      .then(res => {
        dispatch({
          type: actionTypes.CLEAR_SCOUT_NOTIFICATIONS_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CLEAR_SCOUT_NOTIFICATIONS_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to get all notifications data
export const fetchNotifications = data => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_SCOUT_NOTIFICATIONS_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/notifications`, data)
      .then(res => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_NOTIFICATIONS_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_NOTIFICATIONS_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

// Action to get all scout contracts
// export const fetchScoutContracts = (data, onSuccess) => {
//   return async dispatch => {
//     dispatch({
//       type: actionTypes.FETCH_SCOUT_CONTRACTS_INIT,
//     })
//     await api
//       .postwithFormData(`${process.env.REACT_APP_API_URL}/scout/contracts`, data)
//       .then(res => {
//         dispatch({
//           type: actionTypes.FETCH_SCOUT_CONTRACTS_SUCCESS,
//           payload: res,
//         })
//         onSuccess && onSuccess()
//         return
//       })
//       .catch(error => {
//         dispatch({
//           type: actionTypes.FETCH_SCOUT_CONTRACTS_FAILED,
//         })
//         throw error?.response?.data?.error
//       })
//   }
// }

// Action to fetch completed scout tour data
export const completeScoutTour = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.COMPLETE_SCOUT_TOUR_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/scout/tour-completed`)
      .then(res => {
        dispatch({
          type: actionTypes.COMPLETE_SCOUT_TOUR_SUCCESS,
          payload: res,
        })
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.COMPLETE_SCOUT_TOUR_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

//Action for resending email confirmation link for a scout
export const resendEmailConfirmLink = () => {
  return async dispatch => {
    await api
      .get(`${process.env.REACT_APP_API_URL}/scout/email-confirmation-link`)
      .then(res => {
        return
      })
      .catch(error => {
        throw error?.response?.data?.error
      })
  }
}

// Action to get scout invite history
export const getScoutInviteHistory = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_INVITE_HISTORY_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/invite-history`, data)
      .then(res => {
        dispatch({
          type: actionTypes.GET_INVITE_HISTORY_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess()
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_INVITE_HISTORY_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error
      })
  }
}

export const setScoutLoader = () => ({
  type: 'SET_SCOUT_LOADER',
})

export const setHideScoutLoadButton = data => ({
  type: 'SET_HIDE_SCOUT_LOAD_BUTTON',
  payload: data,
})

export const getScoutDealsList = (data, page, totalCount) => ({
  type: 'GET_SCOUT_DEALS_LIST',
  payload: data,
  page,
  totalCount,
})

export const getScoutDeals = (data, page, totalCount) => ({
  type: 'GET_SCOUT_DEALS',
  payload: data,
  page,
  totalCount,
})

export const setHideScoutLoader = () => ({
  type: 'SET_HIDE_SCOUT_LOADER',
})

export const fetchScoutDeals = data => {
  return dispatch => {
    dispatch(setScoutLoader())
    return api
      .post(`${process.env.REACT_APP_API_URL}/scout/get-relevant-startups`, {
        page_num: data,
        page_size: 10,
      })
      .then(res => {
        if (res.result) {
          if (!res?.data?.length) {
            dispatch(setHideScoutLoadButton(true))
          } else {
            dispatch(setHideScoutLoadButton(false))
          }
          if (data === 1) {
            dispatch(getScoutDealsList(res.data, data + 1, res.total_count))
          } else {
            dispatch(getScoutDeals(res.data, data + 1, res.total_count))
          }
          return res
        } else {
          dispatch(setHideScoutLoader())
          if (res.error === 'token expired' && res.is_expired) {
            dispatch(fetchScoutUserLogout())
            dispatch({
              type: 'SET_AUTH_EXPIRED',
            })
          }
          return false
        }
      })
  }
}

export const clearScoutDeals = () => ({
  type: 'CLEAR_SCOUT_DEALS',
})

export const getScoutProfile = onSuccess => {
  return async dispatch => {
    await api
      .get(`${process.env.REACT_APP_API_URL}/scout/profile-data`)
      .then(res => {
        dispatch({
          type: actionTypes?.FETCH_SCOUT_PROFILE_SUCCESS,
          payload: res.data,
        })
        if (res.result && onSuccess) {
          onSuccess(res)
        }
        return res
      })
      .catch(error => {
        throw error?.response?.data?.error || error?.message
      })
  }
}

export const getCarryEntities = (onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.GET_CARRY_ENTITIES_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/scout/get-carry-entities`)
      .then(res => {
        dispatch({
          type: actionTypes.GET_CARRY_ENTITIES_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_CARRY_ENTITIES_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error
      })
  }
}

export const addCarryEntity = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.ADD_CARRY_ENTITY_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/add-carry-entity`, data)
      .then(res => {
        dispatch({
          type: actionTypes.ADD_CARRY_ENTITY_SUCCESS,
        })
        onSuccess && onSuccess(res)
        // return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_CARRY_ENTITY_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error
      })
  }
}

export const setPrimaryCarryEntity = (id, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_PRIMARY_CARRY_ENTITY_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/scout/set-primary-carry-entity?carry_entity_id=${id}`)
      .then(res => {
        dispatch({
          type: actionTypes.SET_PRIMARY_CARRY_ENTITY_SUCCESS,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SET_PRIMARY_CARRY_ENTITY_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error
      })
  }
}

export const viewCarryEntity = (id, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.VIEW_CARRY_ENTITY_INIT,
    })
    await api
      .get(`${process.env.REACT_APP_API_URL}/scout/view-carry-entity?carry_entity_id=${id}`)
      .then(res => {
        dispatch({
          type: actionTypes.VIEW_CARRY_ENTITY_SUCCESS,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.VIEW_CARRY_ENTITY_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error
      })
  }
}

export const fetchScoutContracts = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.FETCH_SCOUT_CONTRACTS_INIT,
    })
    await api
      .post(`${process.env.REACT_APP_API_URL}/scout/get-contracts`, data)
      .then(res => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_CONTRACTS_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_SCOUT_CONTRACTS_FAILED,
        })
        onFail && onFail()
        throw error?.response?.data?.error
      })
  }
}

export const signContractScout = (data, onSuccess) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SIGN_SCOUT_CONTRACTS_INIT,
    })
    await api
      .postwithFormData(`${process.env.REACT_APP_API_URL}/scout/sign-contract`, data)
      .then(res => {
        dispatch({
          type: actionTypes.SIGN_SCOUT_CONTRACTS_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SIGN_SCOUT_CONTRACTS_FAILED,
        })
        throw error?.response?.data?.error
      })
  }
}

export const carryEntityFileUploader = (data, onSuccess, onFail) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.CARRY_ENTITY_FILE_UPLOADER_INIT,
    })
    await api
      .postwithFormData(`${process.env.REACT_APP_API_URL}/scout/carry-entity-file-uploader`, data)
      .then(res => {
        dispatch({
          type: actionTypes.CARRY_ENTITY_FILE_UPLOADER_SUCCESS,
          payload: res,
        })
        onSuccess && onSuccess(res)
        return
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CARRY_ENTITY_FILE_UPLOADER_FAILED,
        })
        onFail && onFail(error?.response?.data?.error)
        throw error?.response?.data?.error
      })
  }
}
