import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Form, FormGroup, Label, Col } from 'reactstrap'
import { Button, Input, InputNumber, Select, Spin } from 'antd'
import { Editor } from '@tinymce/tinymce-react'

import Calendar from '../../../assets/images/tracking/calendar.svg'
import { Container } from 'react-bootstrap'
import * as TrackingActions from '../../../redux/trackingFlowControl/TrackingFlowControlActions'
import './TrackingMonthlyUpdatesForm.scss'
import Spinner from 'react-bootstrap/Spinner'
import HeaderTracking from '../../../components/Header/HeaderTracking/HeaderTracking'
import HeaderTrackingMobile from '../../../components/Header/HeaderTrackingMobile/HeaderTracking'
import FooterTracking from '../../../components/Footer/FooterTrackingMobile/FooterTracking'
import useMediaQuery from 'use-mediaquery'
import { ArrowLeftOutlined } from '@ant-design/icons'

const TrackingMonthlyUpdates = props => {
  const matches = useMediaQuery('(min-width: 768px)')
  const { key_metric_data, single_monthly_update_data, loading } = useSelector(
    state => state.trackingFlowControlReducer,
  )
  const [selectValue, setSelectValue] = useState('')
  const [stateChange, setStateChange] = useState(false)
  const [loader, setLoader] = useState(false)
  const [state, setState] = useState({
    highlights: '',
    key_metric: '',
    key_metric_amount: '',
    challenges: '',
    asks: '',
    goals: '',
  })
  const [errors, setErrors] = useState('')
  const editorRef = useRef(null)

  const dispatch = useDispatch()
  const updateFor = single_monthly_update_data?.data?.update_for
  const month_updateFor = moment(updateFor)?.format('MMMM YYYY')

  // Fetch key metric values
  useEffect(() => {
    dispatch(TrackingActions.fetchKeyMetricValues())
  }, [])

  const stripHtml = html => {
    const tmp = document.createElement('div')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  /**
   * Handle change in form fields.
   * @param {string} value - The new value.
   * @param {string} field - The field to update.
   */
  const handleChange = (value, field) => {
    if (field === 'highlights' || field === 'challenges' || field === 'asks' || field === 'goals') {
      const strippedContent = stripHtml(value)
      if (strippedContent.length <= 1800) {
        setState({ ...state, [field]: value })
      }
      if (!state?.highlights || !state?.challenges || !state?.asks || !state?.goals) {
        setStateChange(true)
      }
    } else {
      setState({ ...state, [field]: value })
      if (!state?.key_metric_amount) {
        setStateChange(true)
      }
    }
  }

  /**
   * Handle the selection of a value in the form field.
   * @param {string} value - The selected value.
   * @param {string} field - The field to update.
   */
  const handleSelectValue = (value, field) => {
    setSelectValue(value)
    setState({ ...state, [field]: value })
    if (!state?.key_metric) {
      setStateChange(true)
    }
  }

  /**
   * Validate a specific form field based on title.
   * @param {string} title - The title of the field to validate.
   * @returns {boolean} - Returns true if the field is valid.
   */
  const handleValidation = title => {
    let errorObj = {}
    let formIsValid = true
    if (!state[title] && title !== undefined) {
      errorObj[title] = 'This field is required'
    }
    setErrors(errorObj)
    return formIsValid
  }

  /**
   * Validate the entire form.
   * @returns {boolean} - Returns true if the form is valid.
   */
  const validateForm = () => {
    let isValid = true
    let errObj = {}
    if (!state?.highlights) {
      errObj.highlights = 'This field is required'
      isValid = false
    }
    if (!state?.key_metric) {
      errObj.key_metric = 'This field is required'
      isValid = false
    }
    if (!state?.key_metric_amount) {
      errObj.key_metric_amount = 'This field is required'
      isValid = false
    }
    if (!state?.challenges) {
      errObj.challenges = 'This field is required'
      isValid = false
    }
    if (!state?.asks) {
      errObj.asks = 'This field is required'
      isValid = false
    }
    if (!state?.goals) {
      errObj.goals = 'This field is required'
      isValid = false
    }
    setErrors(errObj)
    return isValid
  }

  // Submit the monthly update form.
  const submitMonthlyUpdate = async () => {
    const isValid = validateForm()
    if (!isValid) {
      return
    }
    setLoader(true)
    const payload = {
      ...state,
      key_metric_amount: parseInt(state.key_metric_amount),
    }

    await dispatch(TrackingActions.addTrackingMonthlyUpdates(payload, () => {}))
    await dispatch(TrackingActions.fetchMonthlyUpdateData())
    props.history.push('/tracking/history')
    setState({
      highlights: '',
      key_metric: '',
      key_metric_amount: '',
      challenges: '',
      asks: '',
      goals: '',
    })
    setErrors('')
    setLoader(false)
  }

  // Array of available metric values
  const metricValues = [
    'Active users in the last day (DAU)',
    'Active users in the last week (WAU)',
    'Active users in the last month (MAU)',
    'Letters of Intent',
  ]

  const onSubmitDisable =
    !state?.highlights ||
    !state?.key_metric ||
    !state?.key_metric_amount ||
    !state?.challenges ||
    !state?.asks ||
    !state?.goals ||
    errors['key_metric_amount']

  return (
    <>
      {matches ? (
        <div className="d-none d-md-block profile-header-sticky">
          <HeaderTracking />
        </div>
      ) : (
        <div className="d-block d-md-none profile-header-sticky">
          <HeaderTrackingMobile />
        </div>
      )}
      <div className="tracking-monthly-bg">
        <Container className="px-0 px-sm-3">
          {loading ? (
            <Spin animation="border" className="spinner" />
          ) : (
            <>
              <div className="trackingBackbtn">
                <a href="#" className="flex align-items-center" onClick={() => props.history.goBack()}>
                  {/* <img src={BackIcon} /> */}
                  <ArrowLeftOutlined />
                  &nbsp; Back
                </a>
              </div>
              <div className="form-main-wrapper pt-sm-0 px-0 px-sm-4">
                <h5 className="mt-3">
                  <img src={Calendar} className="mr-2" alt="" />
                  {month_updateFor}
                </h5>

                <div className="custom-form">
                  <Form>
                    <FormGroup className="position-relative">
                      <Label for="monthsHighLights">This month's highlights</Label>
                      <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        onEditorChange={(content, editor) => handleChange(content, 'highlights')}
                        init={{
                          min_height: 200,
                          plugins:
                            'preview importcss searchreplace autosave save directionality code visualblocks visualchars template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap',
                          menubar: '',
                          toolbar:
                            'undo redo fullpage | fullscreen preview | formatselect | fontsizeselect | fontselect | hr | bold italic underline forecolor | align bullist numlist | wordcount',
                          toolbar_mode: 'wrap',
                          block_formats:
                            'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          wordcount_countcharacters: true,
                          max_chars: 1800,
                          setup: editor => {
                            editor.on('keyup', () => {
                              const charCount = stripHtml(editor.getContent()).length
                              if (charCount > 1800) {
                                editor.setContent(editor.getContent({ format: 'html' }))
                              }
                            })
                          },
                        }}
                        value={state.highlights}
                      />
                      <span className="tracking-monthly-form-error">{errors['highlights']}</span>
                    </FormGroup>
                    <FormGroup row className="mb-0 mb-sm-3">
                      <Col sm={6} className="mb-3 mb-sm-0 d-flex align-items-start flex-column">
                        <Label for="selectAnnualRecurrningRevenue">Your key metric</Label>
                        <Select
                          size="large"
                          style={{ width: '100%' }}
                          onChange={value => handleSelectValue(value, 'key_metric')}
                          value={state.key_metric}
                        >
                          {key_metric_data?.key_metric_values?.map((x, key) => {
                            return (
                              <Select.Option key={key} value={x}>
                                {x}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Col>
                      <Col sm={6} className="mb-3 mb-sm-0 position-relative">
                        <Label for="revenueAmount">
                          {selectValue ? selectValue : 'Your key metric value'}
                        </Label>
                        <div>
                          <InputNumber
                            className="w-100"
                            size="large"
                            maxLength={metricValues?.includes(selectValue) || !selectValue ? 11 : 13}
                            onChange={value => handleChange(value, 'key_metric_amount')}
                            value={state.key_metric_amount}
                            formatter={value =>
                              metricValues?.includes(selectValue) || !selectValue
                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onBlur={() => handleValidation('key_metric_amount')}
                          />
                        </div>
                        <span className="tracking-monthly-form-error position-relative">
                          {errors['key_metric_amount']}
                        </span>
                      </Col>
                    </FormGroup>

                    <FormGroup className="position-relative">
                      <Label for="challenges">Challenges you are facing</Label>
                      <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        onEditorChange={(content, editor) => handleChange(content, 'challenges')}
                        init={{
                          min_height: 200,
                          plugins:
                            'preview importcss searchreplace autosave save directionality code visualblocks visualchars template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap',
                          menubar: '',
                          toolbar:
                            'undo redo fullpage | fullscreen preview | formatselect | fontsizeselect | fontselect | hr | bold italic underline forecolor | align bullist numlist | wordcount',
                          toolbar_mode: 'wrap',
                          block_formats:
                            'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          wordcount_countcharacters: true,
                          max_chars: 1800,
                          setup: editor => {
                            editor.on('keyup', () => {
                              const charCount = stripHtml(editor.getContent()).length
                              if (charCount > 1800) {
                                editor.setContent(editor.getContent({ format: 'html' }))
                              }
                            })
                          },
                        }}
                        value={state.challenges}
                      />
                      <span className="tracking-monthly-form-error">{errors['challenges']}</span>
                    </FormGroup>
                    <FormGroup className="position-relative">
                      <Label for="help">How can we help</Label>
                      <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        onEditorChange={(content, editor) => handleChange(content, 'asks')}
                        init={{
                          min_height: 200,
                          plugins:
                            'preview importcss searchreplace autosave save directionality code visualblocks visualchars template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap',
                          menubar: '',
                          toolbar:
                            'undo redo fullpage | fullscreen preview | formatselect | fontsizeselect | fontselect | hr | bold italic underline forecolor | align bullist numlist | wordcount',
                          toolbar_mode: 'wrap',
                          block_formats:
                            'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          wordcount_countcharacters: true,
                          max_chars: 1800,
                          setup: editor => {
                            editor.on('keyup', () => {
                              const charCount = stripHtml(editor.getContent()).length
                              if (charCount > 1800) {
                                editor.setContent(editor.getContent({ format: 'html' }))
                              }
                            })
                          },
                        }}
                        value={state.asks}
                      />
                      <span className="tracking-monthly-form-error">{errors['asks']}</span>
                    </FormGroup>
                    <FormGroup className="position-relative">
                      <Label for="goals">Goals for next month</Label>
                      <Editor
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        onEditorChange={(content, editor) => handleChange(content, 'goals')}
                        init={{
                          min_height: 200,
                          plugins:
                            'preview importcss searchreplace autosave save directionality code visualblocks visualchars template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap',
                          menubar: '',
                          toolbar:
                            'undo redo fullpage | fullscreen preview | formatselect | fontsizeselect | fontselect | hr | bold italic underline forecolor | align bullist numlist | wordcount',
                          toolbar_mode: 'wrap',
                          block_formats:
                            'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          wordcount_countcharacters: true,
                          max_chars: 1800,
                          setup: editor => {
                            editor.on('keyup', () => {
                              const charCount = stripHtml(editor.getContent()).length
                              if (charCount > 1800) {
                                editor.setContent(editor.getContent({ format: 'html' }))
                              }
                            })
                          },
                        }}
                        value={state.goals}
                      />
                      <span className="tracking-monthly-form-error">{errors['goals']}</span>
                    </FormGroup>
                    <div className="d-flex justify-content-center">
                      <Button
                        className="btn-submit"
                        loading={loader}
                        onClick={submitMonthlyUpdate}
                        disabled={onSubmitDisable}
                      >
                        <span>Submit</span>
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </>
          )}
          {!matches && (
            <div className="d-block d-md-none profile-footer-sticky">
              <FooterTracking />
            </div>
          )}
        </Container>
      </div>
    </>
  )
}

export default withRouter(TrackingMonthlyUpdates)
